import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationSelectors } from '@features/authentification/domain/redux/selectors/authentication.selectors';
import { ConsultantInterface } from '@features/consultant/domain/entities/consultant.interface';
import { ConsultantApiPort } from '@features/consultant/domain/ports/api/consultant-api.ports';
import { Select } from '@ngxs/store';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsultantApiService implements ConsultantApiPort {
  @Select(AuthenticationSelectors.userCabinetId)
  userCabinetId$!: Observable<number>;
  baseApiPath = environment.apiUrl; // Utilisez une variable séparée pour stocker l'URL de base de l'API

  constructor(private http: HttpClient) {}

  // Méthode pour récupérer les consultants en fonction de l'ID du cabinet de l'utilisateur
  getConsultants(): Observable<ConsultantInterface[]> {
    return new Observable<ConsultantInterface[]>(observer => {
      this.userCabinetId$.subscribe({
        next: idCabinet => {
          if (idCabinet) {
            const url = `${this.baseApiPath}/users/cabinet/refactored/${idCabinet}`;
            this.http.get<ConsultantInterface[]>(url).subscribe({
              next: data => {
                observer.next(data);
                observer.complete();
              },
              error: error => observer.error(error),
            });
          } else {
            observer.error(new Error('Cabinet ID is not available'));
          }
        },
        error: error => observer.error(error),
      });
    });
  }
}
