import { mapConsultantForSelect } from '@features/consultant/domain/mappers/consultant-maper-for-select';
import {
  ConsultantState,
  ConsultantStateModel,
} from '@features/consultant/domain/redux/state/consultant.state';
import { SelectItem } from '@libs/select/select-options';
import { createSelector } from '@ngxs/store';

export class ConsultantSelectors {
  static readonly getConsultantById = (consultantId: number) => {
    return createSelector([ConsultantState], (state: ConsultantStateModel) => {
      return state.consultants.find(
        consultant => consultant.id === consultantId
      );
    });
  };

  static getConsultantSelectItems(
    state: ConsultantStateModel
  ): SelectItem[] | undefined | null {
    return mapConsultantForSelect(state.consultants);
  }
}
