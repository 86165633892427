import { LoadSilenceContacts } from '@features/contacts/domain/redux/actions/contatcs.actions';
import { CreateGlobalMandatDto } from '@features/mandats/domain/dto/create-global-mandat.dto';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';
import { MandatApiPort } from '@features/mandats/domain/ports/api/mandat-api.port';
import { LoadSilenceMandats } from '@features/mandats/domain/redux/actions/mandats.actions';
import { AddProspectionMandatsWithoutApiCall } from '@features/mandats/domain/redux/actions/prospection-mandats.actions';
import { MandatStateModel } from '@features/mandats/domain/redux/state/mandats.state';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';

import { StateContext, Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

export class MandatDataUseCases {
  constructor(
    private mandatService: MandatApiPort,
    private store: Store
  ) {}

  async loadMandatWithoutLoading(ctx: StateContext<MandatStateModel>) {
    try {
      const mandats = await lastValueFrom(this.mandatService.getMandats());
      const mandatsFiltered = mandats.filter(
        mandat => mandat.mandat.etat !== MandatStateEnum.prospection
      );
      const prospectMandats = mandats.filter(
        mandat => mandat.mandat.etat === MandatStateEnum.prospection
      );
      ctx.patchState({
        mandats: mandatsFiltered,
      });

      this.store.dispatch(
        new AddProspectionMandatsWithoutApiCall(prospectMandats)
      );
    } catch (error) {
      console.error('Erreur lors du chargement des mandats', error);
    }
  }

  async createMandat(mandat: CreateGlobalMandatDto): Promise<void> {
    try {
      await lastValueFrom(this.mandatService.createMandat(mandat));
      this.store.dispatch([
        new LoadSilenceMandats(),
        new LoadSilenceContacts(),
      ]);
    } catch (error) {
      console.error('La requête a échoué:', error);
    }
  }

  addAddressToMandatSelection(
    ctx: StateContext<MandatStateModel>,
    address: AddressComponentInterface
  ) {
    try {
      ctx.patchState({
        salePointAddressSelection: undefined,
      });

      ctx.patchState({
        salePointAddressSelection: address,
      });
    } catch (error) {
      console.error("Erreur lors de la sélection de l'adresse", error);
    }
  }

  removeAddressFromMandatSelection(ctx: StateContext<MandatStateModel>) {
    try {
      ctx.patchState({
        salePointAddressSelection: undefined,
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de l'adresse", error);
    }
  }

  setSearchAddress(ctx: StateContext<MandatStateModel>, searchAddress: string) {
    try {
      ctx.patchState({
        searchAddress,
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de l'adresse", error);
    }
  }

  setSearchCity(ctx: StateContext<MandatStateModel>, searchCity: string) {
    try {
      ctx.patchState({
        searchCity,
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de l'adresse", error);
    }
  }
}
