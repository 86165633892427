<div
  [ngClass]="{
    'input-focused': checkIfValueExist() || isInputFocused,
    desktop: forDesktop,
    'input-invalid': isTouched && !isInputValueSent && required
  }"
  class="input_slide-container">
  <input
    #autocompleteInput
    (blur)="onFocus(false)"
    (focus)="onFocus(true)"
    [id]="inputId"
    placeholder=""
    type="text" />

  <label
    [class.required]="required"
    [for]="inputId"
    [ngClass]="{ 'error-label': isTouched && !isInputValueSent && required }"
    class="placeholder"
    >{{ placeholder }}</label
  >
  <div class="symbol">
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      class="green-icon"
      fontIcon="location_on">
    </mat-icon>
  </div>
</div>

<div *ngIf="!isInputValueSent && isTouched && required">
  <small class="errors">Le champ adresse est requis</small>
</div>
