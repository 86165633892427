import { NgModule } from '@angular/core';
import { ConsultantApiService } from '@features/consultant/adapters/secondary/real/consultant-api.service';
import { ConsultantApiPort } from '@features/consultant/domain/ports/api/consultant-api.ports';
import { ConsultantAvatarComponent } from './adapters/primary/ui/components/consultant-avatar/consultant-avatar.component';

@NgModule({
  providers: [{ provide: ConsultantApiPort, useClass: ConsultantApiService }],
  declarations: [ConsultantAvatarComponent],
  exports: [ConsultantAvatarComponent],
})
export class ConsultantModule {}
