import { DateTimeInterface } from '@src/shared/interaces/date-time.interface.ts/date-time.interface';

export interface ConsultantInterface {
  id: number;
  id_cabinet: number;
  email: string;
  challenge: number;
  role: ConsultantRole;
  intitule: string;
  couleur: string;
  civilite: ConsultantCivilite;
  prenom: string;
  nom: string;
  tel: string;
  created_at: DateTimeInterface;
  updated_at: DateTimeInterface;
}

export enum ConsultantRole {
  CONSTRUCTOR = 'CONSTRUCTOR',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  SENIOR = 'SENIOR',
  JUNIOR = 'JUNIOR',
  NO_ACCESS = 'NO_ACCESS',
}

export enum ConsultantCivilite {
  MONSIEUR = 'mr',
  MADAME = 'mrs',
}
