import {
  MandatState,
  MandatStateModel,
} from '@features/mandats/domain/redux/state/mandats.state';
import { createSelector, Selector } from '@ngxs/store';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';

export class MandatsSelectors {
  @Selector([MandatState])
  static mandats(state: MandatStateModel): MandatDetailInterface[] {
    return state.mandats;
  }

  @Selector([MandatState])
  static preSelectedAddress(
    state: MandatStateModel
  ): AddressComponentInterface | undefined {
    return state.salePointAddressSelection;
  }

  @Selector([MandatState])
  static searchAddress(state: MandatStateModel): string {
    return state.searchAddress;
  }

  @Selector([MandatState])
  static searchCity(state: MandatStateModel): string {
    return state.searchCity;
  }

  static readonly getMandatById = (mandatId: number) => {
    return createSelector([MandatState], (state: MandatStateModel) => {
      return state.mandats.find(mandats => mandats.mandat.id === mandatId);
    });
  };

  static findMandatByAddress(address: AddressComponentInterface) {
    return createSelector([MandatState], (state: MandatStateModel) => {
      return state.mandats.filter(mandat => {
        return (
          (mandat.salePoints.route === address.route &&
            mandat.salePoints.locality &&
            mandat.salePoints.street_number == address.street_number) ||
          mandat.salePoints.place_id === address.place_id
        );
      });
    });
  }

  static searchMandat(searchTerm: string) {
    return createSelector(
      [MandatState],
      (state: MandatStateModel): MandatDetailInterface[] => {
        const filteredMandats = state.mandats.filter(mandat =>
          this.isMandatMatchingSearchTerm(mandat, searchTerm)
        );
        return this.removeDuplicateMandatsById(filteredMandats);
      }
    );
  }

  private static isMandatMatchingSearchTerm(
    mandat: MandatDetailInterface,
    searchTerm: string
  ): boolean {
    const term = searchTerm.toLowerCase();
    return (
      mandat.salePoints.enseigne?.toLowerCase().includes(term) ||
      mandat.mandat.numero_registre_mandats?.toString().includes(term) ||
      mandat.salePoints.street_number?.toString().includes(term) ||
      mandat.salePoints.route?.toLowerCase().includes(term)
    );
  }

  private static removeDuplicateMandatsById(
    mandats: MandatDetailInterface[]
  ): MandatDetailInterface[] {
    const uniqueMandatsMap = mandats.reduce(
      (map, mandat) => map.set(mandat.mandat.id, mandat),
      new Map<number, MandatDetailInterface>()
    );
    return Array.from(uniqueMandatsMap.values());
  }
}
