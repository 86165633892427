import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';
import { InputSlideModule } from '@libs/input-slide/input-slide.module';
import { IdGeneratoreService } from '@src/shared/id-generator/id-generatore.service';

//eslint-disable-next-line
declare let google: any;

@Component({
  selector: 'app-google-places-autocomplete',
  templateUrl: './google-places-autocomplete.component.html',
  standalone: true,
  styleUrls: ['./google-places-autocomplete.component.scss'],
  imports: [ReactiveFormsModule, MatIcon, NgClass, InputSlideModule, NgIf],
})
export class GooglePlacesAutocompleteComponent implements AfterViewInit {
  @ViewChild('autocompleteInput') autocompleteInput!: ElementRef;
  @Output() formattedAddress: EventEmitter<AddressComponentInterface> =
    new EventEmitter<AddressComponentInterface>();
  @Input({ transform: booleanAttribute }) forDesktop = false;
  @Input() placeholder!: string;
  @Input() required = false;
  public inputId = this.idGeneratorService.generateShortenedId();
  isInputFocused!: boolean;
  isTouched = false;
  isInputValueSent = false;
  private inputValue = '';

  constructor(
    private cdr: ChangeDetectorRef,
    private idGeneratorService: IdGeneratoreService
  ) {}

  ngAfterViewInit(): void {
    this.initAutocomplete();
    this.inputValue = this.autocompleteInput.nativeElement.value;
  }

  onFocus(b: boolean) {
    this.isInputFocused = b;
    this.inputValue = this.autocompleteInput.nativeElement.value;
    this.isTouched = true;
    this.isInputValueSent = true;
    this.cdr.detectChanges();
  }

  checkIfValueExist(): boolean {
    return this.inputValue !== '';
  }

  processAddressComponents(
    //eslint-disable-next-line
    addressComponents: any[],
    placeId: string,
    url: string,
    //eslint-disable-next-line
    geometry: any
  ): void {
    const formattedAddress: AddressComponentInterface = {};

    addressComponents.forEach(component => {
      if (component.types.includes('street_number')) {
        formattedAddress.street_number = component.long_name;
      } else if (component.types.includes('route')) {
        formattedAddress.route = component.long_name;
      } else if (component.types.includes('locality')) {
        formattedAddress.locality = component.long_name;
      } else if (component.types.includes('administrative_area_level_2')) {
        formattedAddress.administrative_area_level_1 = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        formattedAddress.administrative_area_level_2 = component.long_name;
      } else if (component.types.includes('country')) {
        formattedAddress.country = component.long_name;
      } else if (component.types.includes('postal_code')) {
        formattedAddress.postal_code = component.long_name;
      } else if (component.types.includes('point_of_interest')) {
        formattedAddress.comp_adresse = component.long_name;
      }
    });

    formattedAddress.place_id = placeId;
    formattedAddress.url = url;
    const formatted_address_parts = [
      formattedAddress.street_number,
      formattedAddress.route,
      formattedAddress.comp_adresse,
      formattedAddress.postal_code,
      formattedAddress.locality,
    ];

    formattedAddress.formatted_address = formatted_address_parts
      .filter(part => part)
      .join(' ');
    if (geometry && location) {
      formattedAddress.latitude = geometry.location.lat();
      formattedAddress.longitude = geometry.location.lng();
    }

    this.formattedAddress.emit(formattedAddress);
    this.isInputValueSent = true;
  }

  setInputValue(value: string) {
    this.autocompleteInput.nativeElement.value = value;
    this.onFocus(true);
    //make form control valid
    this.isTouched = true;
  }

  private initAutocomplete(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.nativeElement,
      {
        types: ['geocode'],
        componentRestrictions: {
          country: ['fr', 'RE', 'YT', 'MQ', 'GY', 'GP', 'NC'],
        },
      }
    );
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        const formattedAddress = this.processAddressComponents(
          place.address_components,
          place.place_id,
          place.url,
          place.geometry
        );
      }
    });
  }
}
