import { Injectable } from '@angular/core';
import { MandatApiPort } from '@features/mandats/domain/ports/api/mandat-api.port';
import {
  ActivateMandat,
  AddAddressToMandatSelection,
  CreateMandat,
  LoadSilenceMandats,
  RemoveAddressFromMandatSelection,
  SetSearchAddress,
  SetSearchCity,
} from '@features/mandats/domain/redux/actions/mandats.actions';
import { MandatDataUseCases } from '@features/mandats/domain/redux/state/use-cases/mandat-data.use-cases';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';

export class MandatStateModel {
  mandats!: MandatDetailInterface[];
  mandatSelection!: number[];
  salePointAddressSelection!: AddressComponentInterface | undefined;
  searchAddress!: string;
  searchCity!: string;
}

export const defaultMandatState: MandatStateModel = {
  mandats: [],
  mandatSelection: [],
  salePointAddressSelection: undefined,
  searchAddress: '',
  searchCity: '',
};

@State<MandatStateModel>({
  name: 'mandats',
  defaults: defaultMandatState,
})
@Injectable()
export class MandatState implements NgxsOnInit {
  private mandatDataUseCases: MandatDataUseCases;

  constructor(
    public mandatService: MandatApiPort,
    private store: Store
  ) {
    this.mandatDataUseCases = new MandatDataUseCases(mandatService, store);
  }

  ngxsOnInit(ctx: StateContext<MandatStateModel>) {
    ctx.dispatch(new LoadSilenceMandats());
  }

  @Action(LoadSilenceMandats)
  async loadSilenceMandats(ctx: StateContext<MandatStateModel>): Promise<void> {
    await this.mandatDataUseCases.loadMandatWithoutLoading(ctx);
  }

  @Action(CreateMandat)
  async createMandat(
    ctx: StateContext<MandatStateModel>,
    action: CreateMandat
  ): Promise<void> {
    await this.mandatDataUseCases.createMandat(action.mandat);
  }

  @Action(AddAddressToMandatSelection)
  addAddressToMandatSelection(
    ctx: StateContext<MandatStateModel>,
    action: AddAddressToMandatSelection
  ) {
    this.mandatDataUseCases.addAddressToMandatSelection(ctx, action.address);
  }

  @Action(RemoveAddressFromMandatSelection)
  removeAddressFromMandatSelection(ctx: StateContext<MandatStateModel>) {
    this.mandatDataUseCases.removeAddressFromMandatSelection(ctx);
  }

  @Action(SetSearchAddress)
  setAdressSearch(
    ctx: StateContext<MandatStateModel>,
    action: SetSearchAddress
  ) {
    this.mandatDataUseCases.setSearchAddress(ctx, action.searchAddress);
  }

  @Action(SetSearchCity)
  setCitySearch(ctx: StateContext<MandatStateModel>, action: SetSearchCity) {
    this.mandatDataUseCases.setSearchCity(ctx, action.searchCity);
  }

  @Action(ActivateMandat)
  async activateMandat(
    ctx: StateContext<MandatStateModel>,
    action: ActivateMandat
  ) {
    return this.mandatService.activateMandat(action.body).subscribe(() => {
      ctx.dispatch(new LoadSilenceMandats());
    });
  }
}
