import { Injectable } from '@angular/core';
import { ConsultantInterface } from '@features/consultant/domain/entities/consultant.interface';
import { ConsultantApiPort } from '@features/consultant/domain/ports/api/consultant-api.ports';
import { LoadSilenceConsultants } from '@features/consultant/domain/redux/actions/consultants.action';
import { Action, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

export class ConsultantStateModel {
  consultants!: ConsultantInterface[];
}

export const defaultConsultantState: ConsultantStateModel = {
  consultants: [],
};

@State<ConsultantStateModel>({
  name: 'consultants',
  defaults: defaultConsultantState,
})
@Injectable()
export class ConsultantState {
  constructor(public consultantService: ConsultantApiPort) {}

  @Action(LoadSilenceConsultants)
  async loadSilenceConsultants(
    ctx: StateContext<ConsultantStateModel>
  ): Promise<void> {
    try {
      const consultants = await lastValueFrom(
        this.consultantService.getConsultants()
      );
      ctx.patchState({
        consultants: consultants,
      });
    } catch (error) {
      console.error('Erreur lors du chargement des consultants', error);
    }
  }
}
