import {
  VisitFormState,
  VisitFormStateModel,
} from '@features/visit-form/domain/redux/state/visit-form.state';
import { Selector } from '@ngxs/store';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

export class VisitFormSelectors {
  @Selector([VisitFormState])
  static mandatsLength(state: VisitFormStateModel): number {
    return state.mandats.length;
  }

  @Selector([VisitFormState])
  static mandats(state: VisitFormStateModel): MandatDetailInterface[] {
    return state.mandats;
  }

  @Selector([VisitFormState])
  static mandatsIds(state: VisitFormStateModel): number[] {
    return state.mandats.map(mandat => mandat.mandat.id);
  }

  @Selector([VisitFormState])
  static acquereursIds(state: VisitFormStateModel): number[] {
    return state.acquereurs.map(acquereur => acquereur.id);
  }
}
