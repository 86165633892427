import {
  ConsultantInterface,
  ConsultantRole,
} from '@features/consultant/domain/entities/consultant.interface';
import { SelectItem } from '@libs/select/select-options';

export function mapConsultantForSelect(
  consultant: ConsultantInterface | ConsultantInterface[]
): SelectItem[] | undefined | null {
  let consultants: ConsultantInterface[] = [];

  if (Array.isArray(consultant)) {
    consultants = consultant;
  } else if (
    consultant &&
    'consultants' in consultant &&
    Array.isArray(consultant['consultants'])
  ) {
    consultants = consultant['consultants'];
  }

  try {
    const filteredConsultants = consultants.filter(c => {
      return c.role !== ConsultantRole.NO_ACCESS;
    });

    return filteredConsultants.map(consultant => {
      return {
        id: consultant.id,
        name: consultant.nom + ' ' + consultant.prenom,
        value: consultant.id,
      };
    });
  } catch (e) {
    return undefined;
  }
}
