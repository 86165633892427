import { Component, Input, OnInit } from '@angular/core';
import { ConsultantInterface } from '@features/consultant/domain/entities/consultant.interface';
import { ConsultantSelectors } from '@features/consultant/domain/redux/selectors/consultant.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-consultant-avatar',
  templateUrl: './consultant-avatar.component.html',
  styleUrls: ['./consultant-avatar.component.scss'],
})
export class ConsultantAvatarComponent implements OnInit {
  @Input() consultantId!: number;
  consultant$!: Observable<ConsultantInterface | undefined | null>;
  consultant!: ConsultantInterface;
  initials = '';
  color!: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.consultant$ = this.store.select(
      ConsultantSelectors.getConsultantById(this.consultantId)
    );
    this.consultant$.pipe(untilDestroyed(this)).subscribe(data => {
      if (data) {
        this.consultant = data;
        this.initials = `${this.consultant.nom[0]}${this.consultant.prenom[0]}`;
        this.color = '#' + this.consultant.couleur;
      }
    });
  }
}
