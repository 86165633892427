import { CreateGlobalMandatDto } from '@features/mandats/domain/dto/create-global-mandat.dto';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatInterface } from '@features/mandats/domain/entities/mandat.interface';
import { Observable } from 'rxjs';
import { ActivateMandatDto } from '@features/mandats/domain/dto/activate-mandat.dto';

export abstract class MandatApiPort {
  abstract getMandats(): Observable<MandatDetailInterface[]>;

  abstract getArchivedMandats(): Observable<MandatInterface[]>;

  //TODO VOIR POUR LE TYPE
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  abstract createMandat(mandat: CreateGlobalMandatDto): Observable<any>;

  abstract activateMandat(body: ActivateMandatDto): Observable<any>;
}
