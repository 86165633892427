import { CreateGlobalMandatDto } from '@features/mandats/domain/dto/create-global-mandat.dto';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';
import { ActivateMandatDto } from '@features/mandats/domain/dto/activate-mandat.dto';

export class LoadSilenceMandats {
  static readonly type = '[Mandats] Load Silence Mandats';
}

export class CreateMandat {
  static readonly type = '[Mandats] Create Mandat';

  constructor(public mandat: CreateGlobalMandatDto) {}
}

export class AddAddressToMandatSelection {
  static readonly type = '[Mandats] Add Address To Mandat Selection';

  constructor(public address: AddressComponentInterface) {}
}

export class RemoveAddressFromMandatSelection {
  static readonly type = '[Mandats] Remove Address From Mandat Selection';
}

export class SetSearchAddress {
  static readonly type = '[Mandats] Set Search Address';

  constructor(public searchAddress: string) {}
}

export class SetSearchCity {
  static readonly type = '[Mandats] Set Search City';

  constructor(public searchCity: string) {}
}

export class ActivateMandat {
  static readonly type = ' [Mandats] Activate mandat';

  constructor(public body: ActivateMandatDto) {}
}
