import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import parsePhoneNumberFromString from 'libphonenumber-js';

export function internationalPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || control.value.trim() === '') {
      return null;
    }
    const phoneNumber = parsePhoneNumberFromString(control.value, 'FR');
    if (!phoneNumber) {
      return { invalidPhone: { value: control.value } };
    }
    const valid = phoneNumber.isValid();
    return valid ? null : { invalidPhone: { value: control.value } };
  };
}
