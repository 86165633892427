import { BuyerDetailInterface } from '@features/buyers/domain/entities/buyers.interface';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

export class AddMandatToVisitForm {
  static readonly type = '[VisitForm] Add Mandat to Visit Form';

  constructor(public mandat: MandatDetailInterface) {}
}

export class RemoveMandatFromVisitForm {
  static readonly type = '[VisitForm] Remove Mandat from Visit Form';

  constructor(public mandatId: number) {}
}

export class AddAcquereurToVisitForm {
  static readonly type = '[VisitForm] Add Acquereur to Visit Form';

  constructor(public buyer: BuyerDetailInterface) {}
}

export class RemoveAcquereurFromVisitForm {
  static readonly type = '[VisitForm] Remove Acquereur from Visit Form';

  constructor(public acquereurId: number) {}
}

export class ResetVisitForm {
  static readonly type = '[VisitForm] Reset Visit Form';
}

export class CreateVisit {
  static readonly type = '[VisitForm] Create Visit';

  constructor() {}
}
